/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                setTimeout(function() {
                    $('.loader-overlay').velocity('fadeOut');
                }, 200);

                // $(document).foundation();

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    var scrollTimeout;
    var throttle = 20;

    $(window).on('scroll', function () {
        if (!scrollTimeout) {
            scrollTimeout = setTimeout(function () {
                var st = $(this).scrollTop();

                if(st > 10) {
                    $('.header ').addClass('scroll');
                    /* $('.discount ').addClass('scroll'); */
                } else {
                    $('.header ').removeClass('scroll');
                    /* $('.discount ').removeClass('scroll'); */
                }

                scrollTimeout = null;
            }, throttle);
        }
    });

    $('.post-item-click').click(function(){
       $(this).toggleClass('post-item-active');
       $(this).find('.post-content').slideToggle();
       $(this).find('.fa').addClass('gray-icon');
    });

    if ($(window).width() < 640) {
        $('.top-bar').addClass('pushy');
        $('.top-bar').addClass('pushy-right');
        $('.top-bar-right').addClass('pushy-content');
    }

    $(window).resize(function(){
        if ($(window).width() < 640) {
            $('.top-bar').addClass('pushy');
            $('.top-bar').addClass('pushy-right');
            $('.top-bar-right').addClass('pushy-content');
        }else{
            $('.top-bar').removeClass('pushy');
            $('.top-bar').removeClass('pushy-right');
            $('.top-bar-right').removeClass('pushy-content');
        }
    });

    $('.set__height .matt__grey').click(function(){
        var obj = $(this);
        var val = $(this).attr('data-value');
        $('.set__height .matt__grey').removeClass('matt__active');
        obj.addClass('matt__active');

        $('#pa_maten').val(val);
        $('#pa_maten').trigger('change');
    });

    $( "#jaren_select" ).change(function() {
        var input = $( "#jaren_select option:selected" ).text();

        $("#pa_aantal-jaren-abonnement").val(input);
        $('#pa_aantal-jaren-abonnement').trigger('change');
    });

    $('.set__height .kleur').click(function(){
        var obj = $(this);
        var val = $(this).attr('data-value');
        $('.set__height .kleur').removeClass('kleur__active');
        obj.addClass('kleur__active');

        $('#pa_kleur').val(val);
        $('#pa_kleur').trigger('change');
    });

    $('.set__height .kleur_tag').click(function(){
        var obj = $(this);
        var val = $(this).attr('data-value');
        $('.set__height .kleur_tag').removeClass('kleur_tag__active');
        obj.addClass('kleur_tag__active');

        $('#pa_kleur-tag').val(val);
        $('#pa_kleur-tag').trigger('change');
    });


    $('.page-template-template-activate-tag').find('.loader-overlay').addClass('blockimportant');

    $('.page-template-template-activate-tag').find('.loader-overlay').click(function(){
        $('.page-template-template-activate-tag').find('.loader-overlay').removeClass('blockimportant');
       $('.loader-overlay').fadeOut();
       $('.grijs-blok').fadeOut();
    });

    $('.scrollnaarboven').click(function(){
        $('html, body').animate({scrollTop: '0px'}, 300);
    });
    $('.liquid').imgLiquid();

    $('ul.products li').matchHeight();
    $('.product-showcase h4').matchHeight();
    $('.product-showcase .card').matchHeight();

    $('.popup .fas.fa-times').click(function(){
        $('.popup').velocity('fadeOut');
    });

    $('.open_activation-modal').click(function(e){
        e.preventDefault();
        $('.activation_modal').velocity('fadeIn');
    });

    $('.slick-usp').slick({
        dots: false,
        infinite: true,
        arrows: false,
        autoplaySpeed: 2000,
        autoplay: true,
        speed: 800,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });


    $('.slick-prod-single-under').slick({
        dots: false,
        infinite: false,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });


    $('.media-large-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: true,
        infinite: true,
        asNavFor: '.media-thumbnail-slider'
    });

    $('.media-thumbnail-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        asNavFor: '.media-large-slider',
        dots: false,
        focusOnSelect: true,
        arrows: true,
        prevArrow: '<button class="PrevArrow"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button class="NextArrow"><i class="fa fa-angle-right"></i></button>'
    });

    $( ".single_variation_wrap" ).on( "show_variation", function ( event, variation ) {
        console.log(variation);
        console.log('123');
        $('.set__height .text__price').html('&euro;' + variation.display_price);
        
        var slide_num = $('.media-thumbnail-slider .slick-slide img[src="'+ variation.image.gallery_thumbnail_src +'"]').parent().attr('data-num');
    
        if(typeof slide_num !== 'undefined') {
            $('.media-thumbnail-slider').slick('slickGoTo', slide_num);
        }
    });

    $('.zoom').zoom({'magnify': 1.5});

    //slick-prod-single-under

    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
